// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "../fancybox/jquery.fancybox";
@import "z-index";
@import "sprite";
@import "custom-font";


// Font-Awesome
@import "../font-awesome/font-awesome";

//Slick Slider
@import "../slick/slick";
@import "../slick/slick-theme";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "../bootstrap/bootstrap";

//Sonstiges
//@import "connectiv/custom-mobile-nav.scss";

// Unitegallery Styles
@import "../unitegallery/unite-gallery";
@import "../unitegallery/ug-theme-default";

/* ==========================================================================
   Project's custom styles
   ========================================================================== */

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@import "conn-basics";
@import "apartment-box";
@import "apartment-search";
@import "lang-switch";
@import "immoscout-box";
@import "carousel";
@import "estatedetail";
@import "ap-list";
@import "portalswitch";
@import "usercentrics";

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.footer__social-logo {
  margin-top: -6px;
  width: 20px;
  margin-right: 2px;
}

.marke-urban {
  text-align: center;
}

.footer__directlinks--social li {
  margin-bottom: 4px;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
}

.btn.btn--active a {
  color: #ffffff;
}

.btn.btn-download {
  padding-top: 4px;
  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-primary:hover svg path{
  fill: #FFF;
}

.download-arrow path{
  fill: $brand-primary;
}

.row.vcenter {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media(min-width: $screen-md-min) {
    flex-direction: row;
  }

  &>div {
    float: none!important;
    width: 100%;

    &.pull-right {
      @media(min-width: $screen-md-min) {
        order: 99;
      }
    }
  }
}

$brand-height: 52px; //Höhe der Logografik
$brand-padding-vertical: (($navbar-height - $brand-height) / 2);

.navbar-brand {
  padding: $brand-padding-vertical 10px;
}

#navbar {
  position: relative;
}

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

strong, b {
  font-family: "Brandon Bold", Arial, sans-serif;
  font-weight: normal;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Brandon Light", Arial, sans-serif;
  color: $basic-color;
  @include font-size(1.4);
  //
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

.form-control {
  border-radius: 0 !important;
}


.form-outer .form-control {
  border: 1px #979797 solid;
}

a {
  font-family: "Brandon Bold", Arial, sans-serif;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 188px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  will-change: transform;
  background-color: #fff; /* A background-color is required */
  min-height: 100vh;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.mobile-nav {
  background: $brand-primary;
  color: #fff;
}

.navbar-header {
  background: $brand-secondary;
}

.navbar-default {
  box-shadow: 0 0 10px 0 rgba(3,4,4,0.2);
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border: 0;
}

.navbar-search {
  position: relative;
  float: right;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 9px 20px;
  line-height: 50px!important;
  border-radius: 0;
  font-size: 18px!important;
  font-size: 1.8rem!important;
}

.navbar-search.open:before {
  content: "\f00d"!important;
}

.navbar-toggle {
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding: 9px 20px;
  height: 70px;
  border-radius: 0;
  margin-left: 2px;
}

.navbar-toggle.navbar-toggle--right {
  @include font-size(2.0);
  content: "\f0c9";
}

.navbar-toggle.navbar-toggle--right:before {
  content: "\f0c9";
}

.navbar-toggle.navbar-toggle--right.active {
  background-color: $brand-primary;
  margin-left: 0;
}

.navbar-toggle.navbar-toggle--right.active:before {
  content: "\f00d";
  @include font-size(2.5);
  color: #fff;
  margin-left: 2px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 2px;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: $basic-color;
}

.mobile-nav {
  padding-top: 60px;
  padding-right: 15px;
  text-align: right;
}

.mobile-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-nav .level1 > li {
  padding-top: 8px;
  padding-bottom: 0;
  display: block;
}

.mobile-nav .level1 > li > a {
  display: inline-block;
  @include font-size(1.8);
  color: #fff;
  padding-right: 40px;
  padding-bottom: 8px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  min-width: 120px;
}

.mobile-nav .level1 > li.dropdown > a:after {
  font-family: "FontAwesome";
  content: "\f107";
  position: absolute;
  right: 5px;
  top: 10px;
  @include font-size(1.8);
}

.mobile-nav .level1 > li.dropdown.open > a:after {
  content: "\f106";
}

.mobile-nav .level1 > li.dropdown.open > a {
  border-bottom: 0;
}

.mobile-nav .level1 > li:last-child > a {
  border-bottom: 0;
}

.mobile-nav .level2 {
  padding-right: 40px;
  transition: opacity 1s ease-out;
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.mobile-nav .level2 > li > a {
  display: block;
  padding: 0 0 5px 0;
  @include font-size(1.4);
  color: #fff;
  font-family: "Brandon Light", Arial, sans-serif;
}

.mobile-nav .dropdown.open > .level2 {
  padding-bottom: 15px;
  padding-top: 0;
  opacity: 1;
  height: auto;
}

.menu-right__language {
  margin-top: 60px;
}

.menu-right__language a {
  font-family: "Brandon Light", Arial, sans-serif;
  display: inline-block;
  padding: 0 15px;
  color: #fff;
  @include font-size(1.6);
  vertical-align: middle;
  margin-top: -4px;
}

.menu-right__language a:last-child {
  padding-right: 0;
}

.menu-right__language a.active {
  font-family: "Brandon Bold", Arial, sans-serif;
  margin-top: -5px;
}

.menu-right__global {
  margin-top: 60px;
}

.menu-right__global a {
  display: inline-block;
  padding: 0 10px;
  color: #fff;
  @include font-size(1.5);
}

.menu-right__global a:last-child {
  padding-right: 0;
}

.menu-right__copyright {
  margin-top: 15px;
  @include font-size(0.9);
  color: rgba(255, 255, 255, 0.6);
}

.navbar-header {
  height: 70px;
}

.navbar-brand {
  position: absolute;
  top: 9px;
  width: 50%;
}

.navbar-brand {
  position: absolute;
  z-index: 1;
  width: 100%;
}

.navbar {
  border: 0;
}

.logo {
  //position: absolute;
}

.logo a {
  position: absolute;
  top: 12px;
  margin-left: 5px;
}

.logo img {
  width: 134px;
}

.logo-glow {
  width: 355px;
  margin-left: -110px;
  position: absolute;
}

.jumbotron {
  margin: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
}

.jumbotron__scroll {
  display: none;
}

.jumbotron__image-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 215px;
}

.jumbotron--small .jumbotron__image-bg {
  height: 215px;
}

.jumbotron--small {
  margin-bottom: 40px;
}

.jumbotron__box {
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  @include font-size(1.4);
  padding: 7px 15px;
  margin-top: -22px;
}

.jumbotron__headline {
  font-family: "Brandon Bold", Arial, sans-serif;
  margin-bottom: 5px !important;
}

.teaser-outer {
  margin-top: 25px;
}

.teaser-outer .row {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.teaser-outer .col-xs-12, .teaser-outer .col-sm-4 {
  padding-left: 10px;
  padding-right: 10px;
}

.teaser {
  display: block;
  margin-bottom: 25px;
  text-align: center;
}

.teaser__image {
  z-index: 5;
  position: relative;
}

.teaser__label {
  display: inline-block;
  background-color: $brand-primary;
  z-index: 10;
  position: relative;
  color: #fff;
  width: auto;
  min-width: 55%;
  max-width: 80%;
  margin: 0 auto;
  top: -20px;
  text-align: center;
  font-family: "Indie", "Brandon Bold", Arial, sans-serif;
  @include font-size(2.6);
  border: 1px #fff solid;
  box-shadow: 0px 0px 5px -1px rgba(60,180,185,1);
  transform: rotate(-1deg);
  line-height: 28px;
  padding: 2px 15px;
}

.teaser__label--text {
  transform: rotate(1deg);
  display: block;
  margin-top: 2px;
}

.box-border {
  border: 1px $brand-primary solid;
  margin-bottom: 20px;
}

.box-border-inner {
  border: 10px #fff solid;
  background: #e9e9e9;
  padding: 15px;
  text-align: center;
  min-height: 276px;
}

.select-wrapper {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  border: 1px #e1e1e1 solid;
}

.select-wrapper:before {
  content: '\f107';
  font-family: FontAwesome;
  color: #000;
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 11px;
  pointer-events: none;
  @include font-size(1.4);
}

.select-wrapper select {
  box-sizing: border-box;
  width: 100%;
  padding: 7px 10px;
  border: none;
  appearance: none;
  @include font-size(1.4);
  font-family: "Brandon Bold", Arial, sans-serif;
}

.select-wrapper select::-ms-expand {
  display: none;
}

select.box-border-inner__select:focus {
  outline: none;
}

.box-border-inner input[type=button] {
  margin: 0 auto;
  display: inherit;
}

.btn {
  background-color: transparent;
  border: 1px $brand-primary solid;
  color: $brand-primary;
  border-radius: 0;
  font-family: "Brandon Bold", Arial, sans-serif;
  @include font-size(1.4);
  padding: 7px 20px;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-bottom: 10px;
}

.btn i.fa {
  margin-right: 5px;
}

.btn:hover, .btn--active {
  background-color: $brand-primary;
  color: #fff;
}

.btn--active {
  border: 1px #fff solid;
}

.slider .slider__item {
  display: block;
  height: 330px;
  background-position: center center;
  background-size: cover;
  position: relative;
  outline: none;
}

.slider .slider__item__box {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
  font-family: "Brandon Bold", Arial, sans-serif;
  @include font-size(1.7);
  padding: 10px 20px 25px 20px;
  width: 70%;
  text-align: center;
  line-height: 20px;
}

.slider__item__box__headline {
  margin-bottom: 0 !important;
}

.slider .slick-dots  {
  bottom: 40px;
}

.slider .slick-dots li {
  width: 10px;
  height: 2px;
  margin: 0 3px;
}

.slider .slick-dots li button {
  background: $brand-primary;
  width: 10px;
  height: 2px;
  padding: 0;
  opacity: 0.5;
}

.slider .slick-dots li.slick-active button {
  opacity: 1;
}

.slick-dots li button:before {
  content: none;
}

.textblock {
  text-align: center;
}

.headline, h1 {
  font-family: "Brandon Bold", Arial, sans-serif;
  @include font-size(1.8);
  margin-bottom: 5px;
  display: block;
}

.testimonial__item {
  display: block;
  height: 340px;
  background-position: 25%;
  position: relative;
  background-size: cover;
  outline: none;
}

.testimonial__item__box {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  bottom: 61px;
  left: 50%;
  transform: translate(-50%);
  @include font-size(1.4);
  padding: 10px 15px;
  width: 75%;
}

.testimonial__item__box--name {
  display: inline-block;
  margin-top: -5px;
  margin-left: -5px;
  font-family: "Indie", Arial, sans-serif;
  @include font-size(2.2);
}

.testimonial__item__button {
  position: absolute;
  bottom: 13px;
  left: 50%;
  transform: translate(-50%);
  border: 1px #fff solid;
  white-space: nowrap;
  text-shadow: 0px 0px 10px rgba(150, 150, 150, 1);
  color: #fff;
}

footer {
  background-color: #343434;
  padding: 25px 0 10px 0;
  line-height: 22px;
  color: #fff;
  @include font-size(1.1);
}

footer i.fa {
  margin-right: 4px;
}

footer a {
  color: #fff;
  font-family: "Brandon Light", Arial, sans-serif;
}

footer hr {
  border-color: #fff;
  margin: 0 0 6px 0;
}

footer ul {
  margin: 0;
  padding: 0 0 25px 0;
}

footer ul li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

footer .footer_headline {
  display: block;
  font-family: "Brandon Bold", Arial, sans-serif;
}

footer .copyright {
  @include font-size(0.9);
}

footer .copyright.copyright_link {
  float: right;
  display: inline-block;
  margin-left: 10px;
}

.fullfilled .form-group label, .order-tickets-outer label {
  display: none;
}

section {
  margin: 30px 0;

  @media (min-width: $screen-tablet) {
    margin: 60px 0;
  }
}

section.fullfilled + section.fullfilled,
section.fullfilled + footer {
  margin-top: -30px;

  @media (min-width: $screen-tablet) {
    margin-top: -60px;
  }
}

section.section-not_found {
  margin-top: 80px;

  @media (min-width: $screen-tablet) {
    margin-top: 110px;
  }

  @media (min-width: $screen-desktop) {
    margin-top: 130px;
  }

  @media (min-width: $screen-lg-desktop) {
    margin-top: 150px;
  }
}

.no-bottom-margin {
  margin-bottom: 0;
}

.box-teaser {
  margin-bottom: 15px;
  display: block;
  color: #333;
}

.box-teaser-inner {
  display: block;
  padding: 40px 10px 20px 10px !important;
}

.box-icon {
  margin-bottom: 22px;
  height: 73px;
}

.box-headline {
  font-family: "Brandon Bold", Arial, sans-serif;
  text-transform: uppercase;
  display: block;
  margin-bottom: 15px;
  @include font-size(1.6);
}

.box-text {
  display: block;
  @include font-size(1.7);
  -moz-hyphens: auto;
  -o-hyphens: auto;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.ap-list {
  margin-bottom: 20px;
  position: relative;
}

.standort-box {
  min-height: auto;
  height: auto;
}

.standort-box__headline {
  background-color: $brand-primary;
  color: #fff;
  font-family: "Indie", "Brandon Bold", Arial, sans-serif;
  @include font-size(2.4);
  display: inline-block;
  padding: 0 10px;
  transform: rotate(-1deg);
  position: absolute;
  top: 7px;
  left: 20px;
}

.standort-box__subline {
  margin-top: 30px;
  margin-bottom: 10px;
  display: block;
}

.standort-box__headline--text {
  transform: rotate(1deg);
}

.ap-detail-list {
  margin-bottom: 30px;
}

.ap-detail-list__image {
  height: 240px;
  background-size: cover;
}

.ap-detail-list__image a {
  display: block;
  height: 100%;
  position: relative;
}

.ap-detail-list__image a:after {
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
  content: "\f24d";
  bottom: 5px;
  right: 5px;
  color: #fff;
}

.ap-detail-list__image--blur {
  height: 200px;
  width: 31%;
  position: absolute;
  filter: blur(3px);
  background-repeat: no-repeat;
  background-size: cover;
}

.ap-detail-list__image--groundplan {
  max-height: 80%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}

.ap-detail-list-box--outer {
  background-position: top center;
  background-size: 290%;
}

.ap-detail-list-box {
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  position: relative;
}

.ap-detail-list__headline {
  display: block;
  font-family: "Indie", "Brandon Bold", Arial, sans-serif;
  @include font-size(2.6);
  color: $brand-primary;
}

.ap-detail-list__subline {
  display: block;
  font-family: "Brandon Bold", Arial, sans-serif;
  @include font-size(2.0);
  margin-top: -10px;
}

.ap-detail-list__price {
  float: right;
  text-align: right;
}

.ap-detail-list__price--amount {
  font-family: "Brandon Bold", Arial, sans-serif;
  @include font-size(2.6);
  position: relative;
}

.ap-detail-list__price--amount:before {
  content: "\20AC";
  position: absolute;
  right: -10px;
  top: 2px;
  @include font-size(1.6);
  font-family: "Brandon Light", Arial, sans-serif;
}

.ap-detail-list__price--unit {
  display: block;
}

.ap-detail-list-box .btn {
  width: 100%;
  margin-bottom: 10px;
}

.ap-detail-list-box .btn i.fa {
  margin-right: 10px;
}

.ap-detail-list-box .btn--expose {
  border: 1px #fff solid;
  color: #434343;
  background-color: #e3e3e3;
}

.ap-detail-list-box .btn--expose:hover {
  color: #fff;
  background-color: $brand-primary;
}

.apartment-search-result__infos {
  float: right;
}

.apartment-search-result__price {
  display: block;
  text-align: right;
}

.apartment-search-result__size {
  display: block;
  text-align: right;
}

.apartment-search-result__list {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
}

.apartment-search-result__list li:before {
  content: "\f00c";
  font-family: FontAwesome;
  margin-right: 5px;
}

.benefits-icons .box-headline {
  @include font-size(1.4);
}

.benefits-icons__imgwrapper {
  height: 73px;
  margin-bottom: 20px
}

.benefits-icons__imgwrapper img.box-icon {
  height: auto!important;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  max-height: 100%;
}

.benefits-icons {
  width: 50%;
  display: block;
  text-align: center;
  margin-bottom: 50px;
  float: left;
}

.flex-icons {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.form-outer {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
}

.form-control {
  color: #434343;
}

.form-control label {
  color: #434343;
}

.contact {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  //margin-top: -30px;
}

.contact_box {
  width: 100%;
  overflow: hidden;
  background-size: cover;
}

.contact__left {
  padding: 30px 10px;
}

.contact__headline {
  display: inline-block;
  background-color: #434343;
  color: #fff;
  @include font-size(1.4);
  padding: 0 7px;
  font-family: "Brandon Bold", Arial, sans-serif;
  transform: rotate(-1deg);
}

.contact__subline {
  display: inline-block;
  background-color: $brand-primary;
  color: #fff;
  font-family: "Indie", "Brandon Bold", Arial, sans-serif;
  @include font-size(2.2);
  padding: 0 10px;
  transform: rotate(-1deg);
  margin-top: -1px;
}

.contact {
  background-color: $brand-secondary;
}

.contact__text {
  padding: 20px;
}

.logo__glow {
  box-shadow: 0 0 100px 0 #fff;
}

.navbar-form {
  border: 1px #ddd solid;
  border-color: #ddd !important;
  position: absolute;
  top: 0;
  width: 100%;
  background: linear-gradient(to bottom, #f4fafa 0%,#e7eded 100%);
  padding: 75px 10px 10px 10px;
  margin: 0;
  display: none;
}

.navbar-form *:hover {
  cursor: pointer;
}

.navbar-form input.form-control {
  height: 44px;
}

.navbar__language {
  top: 9px;
}

.navbar-form .btn {
  border: 0;
  font-family: FontAwesome;
  color: #434343;
  @include font-size(2.0);
  margin-bottom: 0;
}

.navbar-form .btn:hover {
  background: transparent;
  color: #434343;
}

.navbar-form .input-group>.form-control {
  background: transparent;
  border: 0;
  box-shadow: none;
  @include font-size(2.0);
  font-family: "Brandon Bold", Arial, sans-serif;
  padding: 0 0 0 25px;
  width: 190px;
  color: #434343;
  max-height: 44px;
  line-height: 44px;
}

.navbar-form .input-group {
  border: 1px solid #3cb4b9;
  background-color: #daeeee;
}

.lang__select {
  text-transform: uppercase;
  font-weight: bold;
}

.lang__select:not(:last-of-type):after {
  content: " | ";
  padding: 0 5px;
}

.accordion {
  margin-bottom: 80px;
}

.accordion-content.closed > .accordion-text-content {
  display: none;
}

.accordion-content.opened > .accordion-text-content {
  display: block;
}

.accordion-content {
  position: relative;
  margin-bottom: 10px!important;
}

.accordion-content > span, .accordion-content.closed > span {
  display: block;
  color: $brand-primary;
  background-color: #f7f7f7;
  padding: 9px 25px 8px 25px;
  margin-bottom: 0!important;
  transition: background-color 0.2s ease-in, color 0.2s ease-in;
}

.accordion-content > span:hover {
  cursor: pointer;
  background-color: $brand-primary!important;
  color: #fff;
}

.opened > span {
  /*background-color: #174E86!important;*/
  background-color: $brand-primary!important;
  color: #FFFFFF;
}

.accordion-text-content_inner {
  background-color: #fafafa;
  padding: 15px 25px 25px 25px;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0px;
  height: 0;
  overflow: hidden;
  margin-top: 20px;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.order-tickets-overlay {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5000;

  &.order-tickets-box--open {
    display: block;
  }

  .info-overlay-bg {
    display: block;
    background: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: 1;
  }

  .order-tickets-outer {
    position: relative;
    top: 0;
    bottom: 0;
    display: block;
    height: 100%;
    overflow: scroll;

    .order-tickets-box {
      position: absolute;
      z-index: 100;
      top: 0;
      left: 50%;
      transform: translate(-50%,0%);
      background-color: #fff;
      padding: 10px;
      margin: 50px 0;
      width: 90%;
    }

    .order-tickets-box-close {
      float: right;
    }
  }
}

.container.container-contact+.container.container-contact {
  margin-top: 30px;
}

select.apartment-search-form__select:disabled {
  cursor: not-allowed;
  opacity: .65;
}

.btn.disabled:hover, .btn[disabled]:hover, fieldset[disabled] .btn:hover {
  background-color: transparent;
  color: $brand-primary;
}

.image-fullwidth {
  width: 100%;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 250px;

    @media (min-width: $screen-tablet) {
      height: auto;
    }
  }
}

@media (max-width: 767px) {

}


/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .mobile-nav {
    display: none;
  }

  .ap-detail-list {
    box-shadow: 0 0 10px 0 rgba(3,4,4,0.2);
  }

  .ap-detail-list-box {
    padding-bottom: 120px;
  }

  .ap-detail-list__btns {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
  }

  .box-teaser__flex-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: left;
  }

  .box-teaser__flex-row > div {
    margin-bottom: 30px;
    flex: 1 1 auto;
  }

  .form-control {
    @include font-size(1.4);
    margin-bottom: 20px;
    height: 44px;
  }

  .contact {
    //margin-top: -60px;
  }

  .contact__text {
    padding: 30px;
  }

  .form-outer {
    padding: 50px;
  }

  .benefits-icons {
    width: 33.33333%;
    margin-bottom: 50px;
  }

  .benefits-icons {
    text-align: center;
  }

  .benefits-icons__imgwrapper {
    height: 90px;
  }

  .benefits-icons .box-headline {
    @include font-size(1.8);
  }

  .btn i.fa {
    margin-right: 10px;
  }

  .btn {
    width: auto;
    margin-bottom: 0;
  }

  .apartment-search-result__price {
    float: right;
    margin-left: 50px;
  }

  .apartment-search-result__size {
    float: right;
  }

  .apartment-search-result .btn {
    width: auto;
    margin-bottom: 0;
    margin-left: 10px;
  }

  .box-teaser {
    margin-bottom: 20px;
    height: 100%;
    display: block;
  }

  .box-teaser-inner {
    height: 100%;
  }

  body {
    @include font-size(1.6);
  }

  .headline, h1 {
    @include font-size(2.5);
  }

  .jumbotron {
    height: 400px;
    position: relative;
  }

  .jumbotron.jumbotron--scroll {
    margin-bottom: 120px;
  }

  .jumbotron.jumbotron--small {
    margin-bottom: 60px;
  }

  .jumbotron__scroll {
    display: inline-block;
    position: absolute;
    bottom: -60px;
    left: 50%;
    transform: translateX(-50%);
    background: $brand-primary;
    border-radius: 120px;
    width: 120px;
    height: 120px;
    padding: 40px 50px;
  }

  .jumbotron__image-bg {
    height: 400px;
  }

  .jumbotron--small {
    height: 350px;
  }

  .jumbotron--small .jumbotron__image-bg {
    height: 350px;
  }

  .jumbotron__box {
    padding: 30px 35px;
    @include font-size(1.6);
    line-height: 25px;
    position: absolute;
    bottom: 80px;
    //margin-right: 25px;
    width: 720px;
  }

  .jumbotron__headline {
    margin-bottom: 20px !important;
  }

  .teaser {
    margin-bottom: -20px;
  }

  .teaser-outer {
    margin-top: -35px;
    padding: 15px;
    background: #fff;
    z-index: 15;
    position: relative;
    margin-bottom: -35px;
  }

  .select-wrapper {
    width: 25%;
    float: left;
    margin-right: 22px;
  }

  .slider .slider__item__box {
    padding: 35px;
    text-align: left;
  }

  .slider__item__box__headline {
    margin-bottom: 20px !important;
  }

  .slider__item__box__text {
    font-family: "Brandon Light", Arial, sans-serif;
  }

  .slider .slick-dots li {
    width: 15px;
    height: 4px;
    margin: 0 6px;
  }

  .slider .slick-dots li button {
    width: 16px;
    height: 4px;
  }

  .box-border-inner {
    border: 15px #fff solid;
  }

  .select-wrapper select {
    @include font-size(1.7);
  }

  .apartment-search__button {
    margin-top: 0 !important;
    @include font-size(1.7);
  }

  .textblock {
    text-align: left;
  }

  .testimonial__item__box {
    width: 50%;
    @include font-size(1.7);
    padding: 20px 35px;
    left: 45%;
    transform: none;
    bottom: 65px;
  }

  .testimonial__item__box--name {
    @include font-size(2.8);
  }

  .testimonial__item__box--text {
    margin-top: 10px;
    display: block;
  }

  .testimonial__item__button {
    left: 45%;
    transform: none;
  }

  .btn {
    @include font-size(1.6);
  }

  footer {
    @include font-size(1.2);
  }

  footer .copyright {
    @include font-size(1.1);
  }

  footer .footer_headline {
    margin-bottom: 10px;
  }

  .navbar-default {
    background-color: $navbar-default-bg;
    height: 80px;
  }


  .logo a {
    top: 11px;
    margin-left: -4px;
  }

  .logo img {
    width: 157px;
    //margin-left: -130px;
  }

  .logo-glow {
    width: 417px;
    margin-left: -130px;
  }

  .navbar-nav {
    margin-left: 150px;
  }

  .navbar-default .navbar-nav>li>a {
    line-height: 40px;
    @include font-size(1.7);
    color: $basic-color;
  }

  .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    background-color: transparent;
    color: $basic-color;
  }

  .navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
    background-color: transparent;
    color: $basic-color;
  }

  .navbar-search {
    line-height: 40px!important;
    right: -20px;
    padding: 20px;
  }

  .navbar__language {
    position: absolute;
    right: 0;
    top: 2px;
    @include font-size(1.2);
    z-index: 30;
  }

  .navbar__language a {
    font-family: "Brandon Light", Arial, sans-serif;
    display: inline-block;
    color: #343434;
    vertical-align: middle;
    margin-top: -4px;
  }

  .navbar__language a:last-child {
    padding-right: 0;
  }

  .navbar__language a.active {
    font-family: "Brandon Bold", Arial, sans-serif;
  }

  .nav>li>a {
    padding-left: 11px;
    padding-right: 11px;
  }

  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover {
    background: transparent;
  }

  .dropdown-menu>li:hover>a {
    color: $brand-primary;
  }

  .navbar-nav>li>.dropdown-menu {
    margin-top: -10px;
    border-radius: 0;
    left: 23px;
    border: 0;
    @include font-size(1.8);
    padding: 15px 40px 15px 0;
  }

  ul.dropdown-menu:before {
    content: '';
    background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    top: -7px;
    left: 20px;
    transform: rotate(45deg);
    box-shadow: 0 -1px 12px rgba(0,0,0,.175);
  }
  ul.dropdown-menu:after {
    content: '';
    background: #fff;
    width: 28px;
    height: 15px;
    position: absolute;
    top: 0px;
    left: 14px;
  }

  .standort-box {
    text-align: right;
    height: 190px;
  }

  .standort-box .btn {
    margin-top: 90px;
  }

  .standort-box__headline {
    padding: 0 20px;
    top: 13px;
    left: 170px;
    @include font-size(3.6);
  }

  .standort-box__subline {
    position: absolute;
    left: 210px;
    @include font-size(2.6);
    font-family: "Brandon Bold", Arial, sans-serif;
    margin-top: 40px;
  }

  .standort-box:before {
    content: '';
    position: absolute;
    top: 12px;
    left: 42px;
    background-image: url(../../img/map.png);
    background-repeat: no-repeat;
    width: 140px;
    height: 120px;
  }

  .standort-box.faq-box:before {
    background-image: url(../../img/faq.png);
    background-size: contain;
    width: 120px;
  }

  .contact__left {
    padding: 60px 0 0 0;
    text-align: right;
  }

  .contact__headline {
    @include font-size(1.8);
    margin-right: 10px;
  }

  .contact__subline {
    @include font-size(2.6);
  }

  .flex-row {
    display: flex;
  }

  .va-middle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
  }

  .navbar-form {
    top: 80px;
    width: 270px;
    right: -10px;
    padding: 10px;
  }

}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .order-tickets-overlay .order-tickets-outer .order-tickets-box {
    width: 50%;
  }

  .order-tickets-overlay .form-outer form {
    width: 100% !important;
  }

  .ap-detail-list-box--outer {
    background-position: center -510px;
  }

  .contact__image {
    height: 313px;
    background-size: cover;
  }

  .nav>li>a {
    padding-left: 16px;
    padding-right: 16px;
  }

  .form-control {
    @include font-size(1.8);
    margin-bottom: 30px;
    height: 54px;
  }

  .navbar-brand {
    top: 10px;
  }

  .contact__left {
    padding-top: 260px;
    text-align: right;
  }

  .contact__headline {
    @include font-size(3.0);
    transform: rotate(-1deg);
    padding: 15px 15px 25px 15px;
    margin-right: 140px;
  }

  .contact__subline {
    @include font-size(3.6);
    transform: rotate(-1deg);
    padding: 15px 15px;
    margin-top: -20px;
    margin-left: 20px;
  }

  .benefits-icons {
    width: 20%;
  }

  body {
    @include font-size(1.8);
  }

  .navbar-default {
    height: 105px;
  }

  .navbar-default .navbar-nav>li>a {
    @include font-size(2.0);
    line-height: 60px;
  }

  .logo a {
    margin-left: 4px;
  }

  .logo img {
    width: 216px;
    //margin-left: 3px;
  }

  .logo-glow {
    width: 572px;
    margin-left: -166px;
  }

  .navbar-nav {
    margin-left: 240px;
  }

  .navbar-search {
    line-height: 60px!important;
  }

  .navbar__language {
    top: 5px;
    @include font-size(1.4);
  }

  .headline, h1 {
    @include font-size(3.0);
    line-height: 30px;
    margin-bottom: 5px;
  }

  .jumbotron {
    height: 600px;
  }

  .jumbotron__image-bg {
    height: 600px;
  }

  .jumbotron--small {
    height: 350px;
    margin-bottom: 60px;
  }

  .jumbotron--small .jumbotron__image-bg {
    height: 350px;
  }

  .jumbotron__box {
    @include font-size(2.0);
    line-height: 25px;
    bottom: 170px;
    width: 680px;
    margin-right: 25px;
  }

  .teaser__label {
    @include font-size(4.0);
    width: 85%;
    line-height: 48px;
  }

  .box-border-inner {
    padding: 25px 35px;
    min-height: auto;
  }

  .select-wrapper select {
    @include font-size(2.0);
    padding: 5px 10px;
  }

  .select-wrapper {
    width: 27%;
    margin-right: 21px;
  }

  .select-wrapper:before {
    @include font-size(2.4);
    top: 1px;
  }

  .slider .slider__item {
    height: 767px;
  }

  .slider .slider__item__box {
    left: 45%;
    width: 50%;
    padding: 35px 35px 65px 35px;
    transform: none;
    @include font-size(1.8);
    bottom: 100px;
    line-height: 22px;
  }

  .slider__item__box__headline {
    margin-bottom: 30px!important;
  }

  .slider .slick-dots {
    width: 50%;
    left: 45%;
    text-align: left;
    margin-left: 29px;
    bottom: 130px;
  }

  .slider .slick-dots li {
    width: 26px;
    height: 5px;
  }

  .slider .slick-dots li button {
    width: 24px;
    height: 5px;
  }

  .testimonial__item {
    height: 630px;
  }

  .testimonial__item__box {
    bottom: 200px;
    width: 40%;
    left: 55%;
    @include font-size(1.8);
    line-height: 22px;
    padding: 35px 30px;
  }

  .testimonial__item__box--name {
    @include font-size(3.2);
  }

  .testimonial__item__box--text {
    margin-top: 25px;
  }

  .testimonial__item__button {
    bottom: 145px;
    left: 55%;
  }

  footer {
    padding: 45px 0 20px 0;
    @include font-size(1.6);
    line-height: 30px;
  }

  footer ul {
    padding: 0 0 40px 0;
  }

  footer .copyright {
    @include font-size(1.4);
  }

  footer .copyright.copyright_link {
    margin-left: 20px;
  }

  footer .footer_headline {
    margin-bottom: 20px;
    @include font-size(1.8);
  }

  footer i.fa {
    margin-right: 10px;
  }

  footer hr {
    margin: 0 0 16px 0;
  }

  .standort-box {
    height: 150px;
    min-height: 150px;
  }

  .standort-box .btn {
    margin-top: 15px;
    @include font-size(1.8);
  }

  .standort-box__headline {
    left: 200px;
    @include font-size(3.8);
  }

  .standort-box__subline {
    @include font-size(2.4);
    left: auto;
    right: 50px;
  }

  .ap-detail-list__image {
    height: 344px;
  }

  .ap-detail-list__image--blur {
    height: 344px;
  }

  .ap-detail-list__headline {
    @include font-size(3.0);
  }

  .ap-detail-list-box .btn {
    width: auto;
  }

  .ap-detail-list-box .btn.btn--right {
    float: right;
  }

  .ap-detail-list__price--amount {
    @include font-size(6.0);
    line-height: 60px;
    margin-right: 13px;
  }

  .ap-detail-list__price--amount--range {
    @include font-size(2.2);
    top: -4px;
    left: -25px;
    position: absolute;
    font-family: "Brandon Light";
  }

  .ap-detail-list__price--amount:before {
    @include font-size(1.8);
    top: 25px;
  }

  .ap-detail-list-box ul {
    margin-bottom: 25px;
  }

  .ap-detail-list-box {
    padding: 25px;
    padding-bottom: 75px;
    @include font-size(1.6);
  }

  .ap-detail-list__btns {
    left: 25px;
    right: 25px;
    bottom: 25px;
  }

  .apartment-search-result__buttons {
    margin-top: -55px;
  }

  .textblock {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .teaser-outer {
    margin-top: -110px;
  }

  .footer__directlinks--social li {
    margin-bottom: 0;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .standort-box .btn {
    position: absolute;
    right: 50px;
    bottom: 54px;
  }

  .logo a {
    top: 22px;
  }

  .select-wrapper {
    width: 27.4%;
    margin-right: 23px;
  }

  .navbar-form .input-group {
    border: none;
    background-color: transparent;
  }

  .navbar-form {
    display: block!important;
    border: 1px #ddd solid;
    border-color: #ddd !important;
    position: absolute;
    right: 0;
    top: 36px;
    background: linear-gradient(to bottom, #f4fafa 0%,#e7eded 100%);
    padding: 0;
    width: 250px;
  }

  .navbar-form input.form-control {
    height: 44px;
  }

  .navbar__language {
    top: 9px;
  }

  .navbar-form .btn {
    border: 0;
    font-family: FontAwesome;
    color: #434343;
    @include font-size(2.0);
  }

  .navbar-form .btn:hover {
    background: transparent;
    color: #434343;
  }

  .navbar-form .input-group>.form-control {
    background: transparent;
    border: 0;
    box-shadow: none;
    @include font-size(2.0);
    font-family: "Brandon Bold", Arial, sans-serif;
    padding: 0 0 0 25px;
    width: 190px;
    color: #434343;
  }

  .navbar-default {
    height: 115px;
  }

  .navbar-default .navbar-nav>li>a {
    line-height: 75px;
  }

  .navbar-brand {
    top: 20px;
  }

  .navbar-form .input-group>.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #434343;
  }
  .navbar-form .input-group>.form-control::-moz-placeholder { /* Firefox 19+ */
    color: #434343;
  }
  .navbar-form .input-group>.form-control:-ms-input-placeholder { /* IE 10+ */
    color: #434343;
  }
  .navbar-form .input-group>.form-control:-moz-placeholder { /* Firefox 18- */
    color: #434343;
  }

  .jumbotron__box {
    width: 680px;
  }

  .box-teaser {
    margin-bottom: 0;
    overflow: hidden;
  }

  .box-text {
    display: block;
    height: 0;
    overflow: hidden;
  }

  .box-icon {
    transition: all 0.3s;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .box-teaser:hover .box-icon {
    height: 0;
    margin-bottom: 0;
  }

  .box-teaser .box-textwrapper {
    position: relative;
    display: block;
  }

  .box-teaser:hover .box-textwrapper {
    position: relative;
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }

  .box-teaser:hover .box-text {
    height: 100%;
  }

  .box-border-inner {
    min-height: 250px;
    height: 250px;
    transition: all 0.3s;
  }

  .standort-box {
    min-height: 200px;
    height: 200px;
  }

  .standort-box__headline {
    left: 175px;
  }

  .standort-box__subline {
    left: auto;
    right: 50px;
  }

  .box-teaser:hover .box-border-inner {
    background-color: $brand-primary;
    border: 15px $brand-primary solid;
    color: #fff;
    padding-top: 15px!important;
  }



  .form-outer {
    padding: 90px 110px;
  }

  .form-outer .headline {
    margin-bottom: 75px;
  }

  .form-outer form {
    width: 70% !important;
  }

  .form-outer textarea {
    height: 160px;
  }

  .section-benefits {
    text-align: center;
    margin: 90px 0;
  }

  .order-tickets-overlay .form-outer form {
    width: 100% !important;
  }
}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: 1500px) {
  .slider .slider__item__box {
    width: 740px;
  }

  .testimonial__item__box {
    width: 650px;
    left: 43%;
  }

  .testimonial__item__button {
    left: 43%;
  }

  .standort-box__subline {
    @include font-size(2.4);
    //left: 250px;
  }
}
