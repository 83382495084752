.btn--apartment {
  width: 100%!important;
  margin: 10px 0 0!important;
}

@media (min-width: $screen-tablet) {
  .jumbotron__box--apartment {
    text-align: center;
  }
}

@media (min-width: $screen-desktop) {
  .jumbotron__box--apartment {
    width: 800px;
    margin-left: 70px;
    margin-right: 0;
    bottom: 200px;
  }

  .btn--apartment {
    margin-top: 20px !important;
    font-size: 2rem;
    padding: 5px 20px;
  }
}

@media (min-width: $screen-lg-desktop) {
  .jumbotron__box--apartment {
    width: 800px!important;
    margin-left: 170px;
    bottom: 230px;
  }
}
