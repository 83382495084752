.estatedetail {

  //.container {
  //  @media(max-width: $screen-sm-max) {
  //    padding-left: 10%;
  //    padding-right: 10%;
  //  }
  //}

  section {
    margin-top: 80px;
    @media(min-width: $screen-md-min) {
      margin-top: 160px;
    }
  }

  .estatedetail--carousel {
    margin: 0;
  }

  .estatedetail--intro {
    margin-top: 65px;
    text-align: center;

    .estatedetail--intro--description {
      margin-bottom: 4.5rem;
    }

    @media(min-width: $screen-md-min) {
      margin-top: 85px;
      padding: 0 11rem;
    }
  }

  .estatedetail--highlights {
    margin-top: 110px;
    @media(min-width: $screen-md-min) {
      margin-top: 130px;
    }
    .estatedetail--highlights--row {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    .estatedetail--highlights--col {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: bold;
      text-transform: uppercase;
      width: 50%;
      margin-bottom: 60px;
      font-family: "Brandon Bold", Arial, sans-serif;

      img {
        height: 70px;
        margin-bottom: 15px;
      }

      @media(min-width: $screen-md-min) {
        width: 33%;
      }
      @media(min-width: $screen-lg-min) {
        width: unset;
        margin-bottom: unset;
      }
    }
  }

  .estatedetail--keyfacts {
    margin-top: 40px;
    @media(min-width: $screen-md-min) {
      margin-top: 130px;
    }

    img {
      margin-bottom: 30px;
      width: 100%;
      height: 200px;
      object-fit: cover;

      @media(min-width: $screen-md-min) {
        margin-bottom: 0;
        height: unset;
      }
    }

    .estatedetail--keyfacts--facts {
      margin-bottom: 70px;
    }
    .estatedetail--keyfacts--rent {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      .estatedetail--keyfacts--rent--number {
        font-family: "Brandon Bold", Arial, sans-serif;
        font-weight: bold;
        font-size: 5.5rem;
        line-height: 5.5rem;

        @media(min-width: $screen-md-min) {
          font-size: 8rem;
          line-height: 8rem;
        }
      }
      .estatedetail--keyfacts--rent--euro {
        font-size: 4rem;
        line-height: 5rem;
      }
    }
  }

  .estatedetail--fittings {
    margin-top: 80px;
    @media(min-width: $screen-md-min) {
      margin-top: 160px;
    }

    img {
      margin-bottom: 30px;
      @media(min-width: $screen-md-min) {
        margin-bottom: 0;
      }
    }

    .estatedetail--fittings--expose {
      text-align: center;

      @media(min-width: $screen-md-min) {
        margin-top: 75px;
      }
    }
  }

  .estatedetail--contact {
    .estatedetail--contact--box {
      background-color: $brand-secondary;
      padding: 35px 40px;
    }

    .estatedetail--contact--text {
      margin-bottom: 50px;
    }

    .logo {
      width: 275px
    }

    .btn-primary {
      background-color: $brand-primary;
      color: #FFF;
      border: 1px solid #FFF;
    }
  }
}
