.lang-switch {
  display: none
}

@media (min-width: $screen-tablet) {
  .lang-switch {
    display: block;
    position: absolute;
    top: 5px;
    right: -5px;
    z-index: 9999;
  }
  .lang-switch a {
    font-family: "Brandon Light", Arial, sans-serif;
    display: inline-block;
    position: relative;
    padding: 0 5px;
  }
  .lang-switch a.active {
    color: #343434;
    cursor: default;
  }
  .lang-switch a:not(:last-of-type):after {
    content: "";
    position: absolute;
    display: block;
    height: 14px;
    width: 1px;
    background: $brand-primary;
    top: 6px;
    right: 0;
  }
}