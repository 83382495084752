.portalswitch {
  display: flex;
  overflow: hidden;
  margin-top: 0;
  flex-direction: column;

  @media (min-width: $screen-desktop) {
    flex-direction: row;
    height: 100vh;
  }

  .portalswitch--side {
    position: relative;

    @media (min-width: $screen-desktop) {
      width: 50%;
    }

    .portalswitch--backgroundoverlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &.side-0 .portalswitch--backgroundoverlay{
      background-color: rgba(#3CB4B9, .7);
    }
    &.side-1 .portalswitch--backgroundoverlay{
      background-color: rgba(#EE7768, .7);
    }

    .portalswitch--background {
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 50vh;
        min-height: 350px;
        @media (min-width: $screen-desktop) {
          width: 200%;
          height: 100vh;
          min-height: 850px;
        }
      }
    }
    &.side-1 .portalswitch--background {
      img {
        object-position: right;
        @media (min-width: $screen-desktop) {
          object-position: unset;
          margin-left: -100%;
        }
      }
    }

    .btn {
      border-color: #FFF;
      background-color: #FFF;
    }
    &.side-0 .btn {
      color: #3CB4B9;
    }
    &.side-1 .btn {
      color: #EE7768;
    }
  }

  .portalswitch--content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 60px 35px 20px 35px;
    @media (min-width: $screen-desktop) {
      padding: 145px 85px;
      min-height: 850px;
    }
  }

  .portalswitch--logo {
    display: flex;
    justify-content: center;
    img {
      max-width: 120px;
      @media (min-width: $screen-desktop) {
        max-width: 80%;
      }
    }
  }

  .portalswitch--texts {
      position: absolute;
      padding: inherit;
      left: 0;
      bottom: 0;
    width: 100%;

    .portalswitch--headline {
      font-family: "Brandon Bold", Arial, sans-serif;
      color: #FFF;
      white-space: break-spaces;
      font-size: 18px;

      @media (min-width: $screen-desktop) {
        font-size: 34px;
        margin-bottom: 25px;
      }
    }

    .portalswitch--subline {
      color: #FFF;
      margin-bottom: 20px;
      font-size: 14px;

      @media (min-width: $screen-desktop) {
        font-family: "Brandon Bold", Arial, sans-serif;
        font-size: 20px;
        margin-bottom: 35px;
      }
    }
  }
}
