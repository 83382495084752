//
// Custom font (e.g. for inserting icomoon font)
// --------------------------------------------------

@font-face {
  font-family: 'Indie';
  src: url('../fonts/2f773b_1_0-webfont.woff2') format('woff2'),
  url('../fonts/2f773b_1_0-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Bold';
  src: url('../fonts/hvd_fonts_-_brandontext-bold-webfont.woff2') format('woff2'),
  url('../fonts/hvd_fonts_-_brandontext-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Light';
  src: url('../fonts/hvd_fonts_-_brandontext-light-webfont.woff2') format('woff2'),
  url('../fonts/hvd_fonts_-_brandontext-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}