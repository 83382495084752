.ap-list-box {
  background: $brand-primary;
  padding: 20px;
  color: #fff !important;
  @include font-size(1.4);
  font-family: "Brandon Light", Arial, sans-serif !important;
  display: block;

  .ap-list-small & {
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0;
  }

  @media (min-width: $screen-lg-desktop) {
    @include font-size(2.0);
    transition: all 0.3s;
    display: block;
    background-color: $brand-primary;
    z-index: 10;
    position: absolute;
    color: #fff;
    width: 320px;
    margin: 0 auto;
    text-align: center;
    border: 3px $brand-secondary solid;
    box-shadow: 0 0 5px -1px $brand-primary;
    transform: rotate(0);
    padding: 0;
    bottom: -40px;
    margin-left: -160px;
    left: 50%;
    height: 0%;
    min-height: 92px;
    overflow: hidden;

    .ap-list-small & {
      transform: rotate(0);
      border: none;
      width: 100%;
      margin-left: 0;
      left: 0;
      min-height: 60px;
      box-shadow: none;
      bottom: 0;

      &.hasSubline {
        min-height: 80px;
      }
    }

    .ap-list:hover & {
      background-color: rgba($brand-primary, 0.8);
      height: 100%;
      transform: rotate(0);
      //top: 0;
      left: 0;
      //right: 0;
      bottom: 0;
      width: 100%;
      box-shadow: none;
      border: 0;
      padding: 25px;
      margin-left: 0;
    }
  }

  .btn {
    margin-top: 15px;
    border: 1px #fff solid;
    color: #fff;
    text-transform: uppercase;

    .ap-list-small & {
      display: none;
    }

    @media (min-width: $screen-lg-desktop) {
      border: 1px solid transparent;
      display: block;
      height: 0;
      font-size: 0;
      padding: 0;
      margin: 0;

      .ap-list:hover & {
        border: 1px solid #fff;
        display: inline-block;
        height: auto;
        padding: 7px 20px;
        @include font-size(1.8);
        margin: 0 auto;
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @media (min-width: $screen-lg-desktop) {
      display: block;
      height: 0;
      font-size: 0;
      padding: 0;
      margin: 0;
      // overflow: hidden;
      transition: height 0.2s, font-size 0.2s 0.2s;

      .ap-list:hover & {
        display: block;
        height: 145px;
        font-size: 14px;
        padding: 0;
        margin: 20px 0;
      }
    }

    li:before {
      content: "\f00c";
      font-family: FontAwesome;
      margin-right: 5px;
    }
  }
}

.ap-list__headline {
  display: block;
  font-family: Indie;
  @include font-size(2.6);

  @media (min-width: $screen-lg-desktop) {
    @include font-size(4.0);
  }
}

.ap-list__subline {
  display: block;
  @include font-size(1.4);
  text-transform: uppercase;

  @media (min-width: $screen-lg-desktop) {
    font-family: "Brandon Light", Arial, sans-serif;
    margin-bottom: 5px;
    margin-top: -8px;
    @include font-size(2.0);
  }

  .ap-list-small & {
    font-size: 16px;
  }
}


.ap-list-image {
  overflow: hidden;

  .ap-list-small & {
    height: unset;

    img {
      width: 100%;
      max-width: unset;
      @media (min-width: $screen-lg-desktop) {
        max-width: 100%;
      }
    }

  }
}

.teaser__label--text {
  @media (min-width: $screen-lg-desktop) {
    margin-left: 10px;
    margin-right: 10px;
    .ap-list:hover & {
      transform: none;
    }

    .ap-list-small:hover & {
      margin-top: 90px;
    }
  }
}

.ap-detail-list-box {
  padding: 35px 45px 70px 45px;
  @include font-size(1.4);

  @media (min-width: $screen-lg-desktop) {
    @include font-size(1.8);
  }

  .ap-detail-list__price--amount {
    @include font-size(5.5);
    line-height: 55px;
    margin-right: 13px;

    @media (min-width: $screen-lg-desktop) {
      @include font-size(8.0);
      line-height: 80px;
      margin-right: 25px;
    }
  }

  .ap-detail-list__price--amount:before {
    @include font-size(2.6);
    top: 20px;
    right: -14px;

    @media (min-width: $screen-lg-desktop) {
      @include font-size(4);
      top: 29px;
      right: -26px;
    }
  }

  .ap-detail-list__headline {
    @include font-size(2.5);
    margin-bottom: 5px;
    @media (min-width: $screen-lg-desktop) {
      @include font-size(3.5);
      margin-bottom: 10px;
    }
  }

  .ap-detail-list__subline {
    @include font-size(2.0);
    @media (min-width: $screen-lg-desktop) {
      @include font-size(3.0);
    }

  }

  .ap-detail-list__btns {
    bottom: 15px;
  }

  ul {
    margin: 15px 0;
    padding: 0;
    list-style-type: none;
    @include font-size(1.4);

    @media (min-width: $screen-lg-desktop) {
      @include font-size(1.8);
    }

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        content: "\f00c";
        font-family: FontAwesome;
        margin-right: 5px;
        position: absolute;
        left: 0;
        font-size: 1.5rem;

        @media (min-width: $screen-lg-desktop) {
          font-size: 2.1rem;
        }
      }
    }
  }
}



