.apartment-search-result a.openSlider {
  display: block;
  position: relative;
}
.apartment-search-result a.openSlider:after {
  position: absolute;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
  content: "\f24d";
  bottom: 5px;
  right: 5px;
  color: #fff;
}

.apartment-search-result .btn {
  width: 100%;
  margin-bottom: 10px;
}

.apartment-search-result .btn--expose {
  border: 1px #434343 solid;
  color: #434343;
  background-color: #e3e3e3;
}

.apartment-search-result .btn--expose:hover {
  color: #fff;
  background-color: #3cb4b9;
  border-color: #3cb4b9;
}

.apartment-search__button {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px !important;
}

.apartment-search__headline {
  margin-bottom: 10px !important;
}

.apartment-search__inputs {
  display: flex;
  margin-top: 30px;
  flex-direction: column;

  @media (min-width: $screen-tablet) {
    flex-direction: row;
  }

  .select-wrapper {
    flex-grow: 2;
    width: unset;
    float: none;
    margin-bottom: 10px;

    @media (min-width: $screen-tablet) {
      margin-bottom: 0;
    }
  }
}
