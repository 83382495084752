.carousel-inner {
  min-height: 200px;

  &>.item>img {
    max-width: unset;
    width: 100%;
  }

  @media(max-width: $screen-xs-max) {
    &>.item>a>img,
    &>.item>img {
      max-width: unset;
      height: 300px;
      margin-left: -35%;
    }
  }
  @media(min-width: $screen-md-min) {
    min-height: unset;
  }
}

.carousel-textlayer {
  position: relative;
  background-color: rgba(255,255,255,.9);
  text-align: center;
  z-index: 15;
  padding: 15px 20px;
  margin: -40px 15px 0 15px;

  @media(min-width: $screen-md-min) {
    position: absolute;
    left: 50%;
    padding: 30px;
    width: 560px;
    bottom: 10%;
    margin-left: -280px;
  }


  .carousel-textlayer--text {
    margin-bottom: 20px;
    @media(min-width: $screen-md-min) {
      margin-bottom: 45px;
    }
  }
}

.carousel-indicators {
  margin-bottom: 0;

  .slick-dots, .slick-next, .slick-prev {
    position: unset;
  }

  li {
    border-radius: 0;
    border: none;
    background-color: rgba($brand-primary, .5);
    width: 25px;
    height: 5px;

    &.slick-active,
    &.active {
      background-color: $brand-primary;
      width: 25px;
      height: 5px;
      margin: 1px;
    }
  }
}

.slick-track {
  @media(max-width: $screen-xs-max) {
    height: 300px;
    .slick-slide {
      object-fit: cover;
    }
  }
}
